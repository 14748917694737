<template>
  <div class="mt-3 progress bg-lightGrey-1 w-full rounded-lg">
    <div
      class="progress-width transition-all"
      :style="{ width: `${progress}%`, background: progressColor }"
    ></div>
  </div>
</template>
<script>
  export default {
    props: {
      progress: {
        type: Number,
        default: 10,
        required: false,
      },
      progressColor:{
        typr: String,
        default:'#6a30c3',
        required: false
      }
    },
  };
</script>
<style scoped>
  .progress {
    background-color: #f3f3f6;
    height: 0.375rem;
    margin-top: 0.875rem;
    position: relative;
    transition: all 0.5s linear;
  }
  .progress-width {
    /* background: #6a30c3; */
    border-radius: 19px;
    z-index: 2;
    height: 0.375rem;
    transition: all 0.5s linear;
  }
</style>
