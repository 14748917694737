var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.changeLimit
      ? _c(
          "div",
          [
            _c("p", { staticClass: "text-lg text-dark" }, [
              _vm._v("Card Limit"),
            ]),
            _vm.spendingControl.limitAmount
              ? _c(
                  "div",
                  [
                    _c("p", { staticClass: "mt-4 text-sm text-grey" }, [
                      _vm._v(
                        " This is the amount you want to spend in a specified period. "
                      ),
                    ]),
                    _c(
                      "p",
                      { staticClass: "mt-4 text-brandPurple font-bold" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatMoney")(
                                _vm.spendingControl.amountSpentSoFar
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("HorizontalProgress", {
                      attrs: {
                        progress: Math.round(
                          (_vm.spendingControl.amountSpentSoFar /
                            _vm.spendingControl.limitAmount) *
                            100
                        ),
                      },
                    }),
                    _c("p", { staticClass: "text-sm text-grey mt-4" }, [
                      _vm._v(
                        " of " +
                          _vm._s(
                            _vm._f("formatMoney")(
                              _vm.spendingControl.limitAmount
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.spendingControl.limitPeriod.toLowerCase()
                          ) +
                          " limit "
                      ),
                    ]),
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "p",
                    { staticClass: "text-grey font-normal text-center py-6" },
                    [_vm._v(" You currently have no limit set ")]
                  ),
                ]),
            _c("Button", {
              staticClass: "mt-6",
              attrs: {
                outline: "",
                width: "w-full",
                text: _vm.spendingControl.limitAmount
                  ? "Change limit"
                  : "Set limit",
                disabled: _vm.details.status === "BLOCKED",
              },
              on: {
                click: function ($event) {
                  _vm.changeLimit = true
                },
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("p", { staticClass: "text-lg text-dark" }, [
              _vm._v("Change Limit"),
            ]),
            _c("p", { staticClass: "mt-4 text-sm text-grey" }, [
              _vm._v(
                " What is the most you want to spend per month? Enter the amount below "
              ),
            ]),
            _c("CurrencyInput", {
              staticClass: "mt-5",
              attrs: {
                placeholder: "Amount I want to spend",
                width: "w-full",
                errorText: "Amount must be created than ₦100",
                validation: _vm.rules.amount,
              },
              model: {
                value: _vm.limit.amount,
                callback: function ($$v) {
                  _vm.$set(_vm.limit, "amount", $$v)
                },
                expression: "limit.amount",
              },
            }),
            _c("Select", {
              staticClass: "mt-5 select",
              attrs: {
                placeholder: "Select duration",
                options: _vm.options,
                reduce: function (type) {
                  return type.value
                },
                errorText: "Please a period",
                validation: _vm.rules.period,
              },
              model: {
                value: _vm.limit.period,
                callback: function ($$v) {
                  _vm.$set(_vm.limit, "period", $$v)
                },
                expression: "limit.period",
              },
            }),
            _c("Button", {
              staticClass: "mt-7",
              attrs: {
                width: "w-full",
                text: "Change limit",
                loading: _vm.loading,
                disabled: _vm.disableButton,
              },
              on: {
                click: function ($event) {
                  return _vm.setCardLimit()
                },
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }