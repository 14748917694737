<template>
  <div>
    <div v-if="!changeLimit">
      <p class="text-lg text-dark">Card Limit</p>
      <div v-if="spendingControl.limitAmount">
        <p class="mt-4 text-sm text-grey">
          This is the amount you want to spend in a specified period.
        </p>
        <p class="mt-4 text-brandPurple font-bold">
          {{ spendingControl.amountSpentSoFar | formatMoney }}
        </p>
        <HorizontalProgress
          :progress="
            Math.round(
              (spendingControl.amountSpentSoFar / spendingControl.limitAmount) *
                100
            )
          "
        />
        <p class="text-sm text-grey mt-4">
          of {{ spendingControl.limitAmount | formatMoney }}
          {{ spendingControl.limitPeriod.toLowerCase() }} limit
        </p>
      </div>
      <div v-else>
        <p class="text-grey font-normal text-center py-6">
          You currently have no limit set
        </p>
      </div>
      <Button
        class="mt-6"
        outline
        width="w-full"
        :text="spendingControl.limitAmount ? 'Change limit' : 'Set limit'"
        @click="changeLimit = true"
        :disabled="details.status === 'BLOCKED'"
      />
    </div>
    <div v-else>
      <p class="text-lg text-dark">Change Limit</p>
      <p class="mt-4 text-sm text-grey">
        What is the most you want to spend per month? Enter the amount below
      </p>
      <CurrencyInput
        placeholder="Amount I want to spend"
        width="w-full"
        class="mt-5"
        v-model="limit.amount"
        errorText="Amount must be created than ₦100"
        :validation="rules.amount"
      />
      <Select
        placeholder="Select duration"
        class="mt-5 select"
        :options="options"
        :reduce="(type) => type.value"
        v-model="limit.period"
        errorText="Please a period"
        :validation="rules.period"
      />
      <Button
        @click="setCardLimit()"
        class="mt-7"
        width="w-full"
        text="Change limit"
        :loading="loading"
        :disabled="disableButton"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { HorizontalProgress } from "@/UI/Progress";
import { Button } from "@/UI/Button";
import { Input, CurrencyInput, Select } from "@/UI/Input";
export default {
  components: {
    HorizontalProgress,
    Button,
    Input,
    CurrencyInput,
    Select,
  },
  props: {
    details: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      limit: {
        amount: 0,
        period: "",
      },
      changeLimit: false,
      spendingControl: {},
      options: [
        { label: "Daily", value: "DAILY" },
        { label: "Weekly", value: "WEEKLY" },
        { label: "Monthly", value: "MONTHLY" },
        { label: "Yearly", value: "YEARLY" },
      ],
      loading: false,
    };
  },
  computed: {
    rules() {
      return {
        amount: this.limit.amount >= 100,
        period: this.limit.period.length > 0
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  mounted() {
    this.spendingControl = this.details.spendingControl;
    this.getCardLimit();
  },
  methods: {
    ...mapActions("card", ["fixCardLimit", "getCardSpendingLimit"]),
    ...mapActions("notification", ["showAlert"]),
    getCardLimit() {
      this.getCardSpendingLimit(this.details.id)
        .then((res) => {
          this.spendingControl = res.data.data;
        })
        .catch((err) => {
          if (err.response.data.message === "Card has no spending limit") {
            this.spendingControl.limitAmount = null;
            // console.log("Card has no spending limit");
            this.showAlert({
              display: false
            });
          }
          this.loading = false;
        });
    },
    setCardLimit() {
      this.loading = true;
      this.fixCardLimit({
        id: this.details.id,
        data: this.limit,
      })
        .then((res) => {
          this.loading = false;
          this.showAlert({
            display: true,
            type: "success",
            description: `Card limit updated successfully`,
          });
          this.getCardLimit();
          this.changeLimit = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
